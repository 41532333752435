<template>
  <div class="content">
    <div class="card">
      <el-button type="primary" @click="handleAdd" icon="el-icon-plus">添加</el-button>
      <el-button type="primary" plain @click="handleAdd" icon="el-icon-upload2">导出</el-button>
      <div class="line"></div>
      <el-select placeholder="请选择公司">
        <el-option label="区域一" value="shanghai"></el-option>
        <el-option label="区域二" value="beijing"></el-option>
      </el-select>
      <el-input placeholder="请输入店长手机号" style="width: 200px;margin-right:10px;"></el-input>
      <el-date-picker
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      ></el-date-picker>
      <el-button type="primary" style="margin-left:10px;">搜索</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" stripe>
        <el-table-column align="center" prop="connection_merchant" label="公司名称"></el-table-column>
        <el-table-column align="center" prop="name" label="门店名称"></el-table-column>
        <el-table-column align="center" prop="admin_name" label="店长姓名"></el-table-column>
        <el-table-column align="center" prop="admin_account" label="店长手机号"></el-table-column>
        <el-table-column align="center" prop="create_admin_user" label="添加人"></el-table-column>
        <el-table-column align="center" prop="create_time" label="添加时间"></el-table-column>
        <el-table-column align="center" prop="audit_text" label="审核状态"></el-table-column>
        <el-table-column align="center" prop="audit_admin_user" label="审核人"></el-table-column>
        <el-table-column align="center" prop="audit_time" label="审核时间"></el-table-column>
        <el-table-column align="center" prop="end_time" label="到期时间"></el-table-column>
        <el-table-column align="center" prop="end_day" label="到期天数"></el-table-column>
        <el-table-column align="center" fixed="right" prop="operation" label="操作"></el-table-column>
      </el-table>
      <div class="pagina">
        <el-pagination background layout="prev, pager, next" :total="total"></el-pagination>
      </div>
    </div>
    <el-dialog
      :visible.sync="visiable"
      width="45%"
      title="新增门店"
      :destroy-on-close="true"
      :before-close="handleCance"
    >
      <el-form :model="shopForm" :rules="storeRules" ref="shopForm">
        <div class="title">
          <div class="span"></div>门店信息
        </div>
        <el-form-item label="公司名称" :label-width="shopFormLabelWidth" >
          <el-select v-model="shopForm.hierarchy_pid" placeholder="请选择公司名称">
            <template v-for="(item, index) in hierarchy_list">
              <el-option :label="item.name" :key="index" :value="item.hierarchy_id"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="门店名称" :label-width="shopFormLabelWidth" prop="name">
          <el-input
            v-model="shopForm.name"
            autocomplete="off"
            placeholder="请输入门店名称"
            style="width: 300px;"
          ></el-input>
        </el-form-item>
        <el-form-item label="门店简称" :label-width="shopFormLabelWidth">
          <el-input
            v-model="shopForm.shop_name"
            autocomplete="off"
            placeholder="请输入门店简称"
            style="width: 300px;"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属区域" :label-width="shopFormLabelWidth" :required="true">
          <el-row>
            <el-col :span="8">
              <el-select v-model="shopForm.provinceId" placeholder="请选择省" @change="handleProvie">
                <template v-for="(item, index) in provinceList">
                  <el-option :label="item.name" :value="item.id" :key="index"></el-option>
                </template>
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-select v-model="shopForm.cityId" placeholder="请选择市" @change="handleCity">
                <template v-for="(item, index) in cityList">
                  <el-option :label="item.name" :value="item.id" :key="index"></el-option>
                </template>
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-select v-model="shopForm.areaId" placeholder="请选择区" @change="handleArea">
                <template v-for="(item, index) in areaList">
                  <el-option :label="item.name" :value="item.id" :key="index"></el-option>
                </template>
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="详细地址" :label-width="shopFormLabelWidth" prop="addr">
          <el-input
            v-model="shopForm.addr"
            autocomplete="off"
            placeholder="请输入详细地址"
            style="width: 300px;"
          ></el-input>
        </el-form-item>
        <el-form-item label="门店LOGO" :label-width="shopFormLabelWidth">
          <div style="display: flex;flex-direction: row;">
            <el-upload
              class="avatar-uploader"
              action="https://api.etouch.top/admin/common.Common/uploadOssImage"
              :data="{
                      directory: 'AGENT_INFO'
                    }"
              :headers="{
                        authorization: token
                    }"
              :show-file-list="false"
              :on-success="handleStoreLogoUrlSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="storeLogo_img" :src="storeLogo_img" class="avatarOther" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="营业执照" :label-width="shopFormLabelWidth">
          <div style="display: flex;flex-direction: row;">
            <el-upload
              class="avatar-uploader"
              action="https://api.etouch.top/admin/common.Common/uploadOssImage"
              :data="{
                      directory: 'AGENT_INFO'
                    }"
              :headers="{
                        authorization: token
                    }"
              :show-file-list="false"
              :on-success="handleStoreLicenseUrlSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="storeLicense_img" :src="storeLicense_img" class="avatarOther" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>
        <div class="title">
          <div class="span"></div>店长账号
        </div>
        <el-form-item label="店长姓名" :label-width="shopFormLabelWidth" prop="admin_name">
          <el-input
            v-model="shopForm.admin_name"
            autocomplete="off"
            placeholder="请输入店长姓名"
            style="width: 300px;"
          ></el-input>
        </el-form-item>
        <el-form-item label="店长手机号" :label-width="shopFormLabelWidth" prop="admin_account">
          <el-input
            v-model="shopForm.admin_account"
            autocomplete="off"
            placeholder="请输入店长手机号"
            style="width: 300px;"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align:right">
        <el-button type="primary" @click="storeSubmit('shopForm')">保存</el-button>
        <el-button @click="handleCance">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { shopList } from "@/api/businessManage/business";
import { getSelectList, getRegionList } from "@/api/agentManage/index";
import Cookies from "js-cookie";
import storage from "good-storage";
export default {
  data() {
    return {
      tableData: [],
      total: 0,
      shopFormLabelWidth: "100px",
      visiable: false,
      storeLogo_url: "",
      storeLogo_img: "",
      storeLicense_url: "",
      storeLicense_img: "",
      shopForm: {},
      provinceList: [],
      cityList: [],
      areaList: [],
      hierarchy_list: [],
      visible: false,
      token: "",
      storeRules: {
        name: [
          {
            required: true,
            message: "请填写门店名称",
            trigger: "blur",
          },
        ],
        addr: [
          {
            required: true,
            message: "请填写详细地址",
            trigger: "blur",
          },
        ],
        admin_name: [
          {
            required: true,
            message: "请输入店长姓名",
            trigger: "blur",
          },
        ],
        admin_account: [
          {
            required: true,
            message: "请输入店长手机号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.fetchData();
    this.getProvinceList();
    this.getSelectList();
    this.token = storage.session.get("token") || Cookies.get("token");
  },
  methods: {
    /*
     *  商家数据
     */
    getSelectList() {
      const data = {
        type: 2,
      };
      getSelectList(data).then((res) => {
        if (res.code === 0) {
          this.hierarchy_list = res.data;
        }
      });
    },
    /*
     *  门店列表
     */
    fetchData() {
      shopList().then((res) => {
        if (res.code === 1) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /*
     *  关闭弹窗
     */
    handleCance() {
      this.visiable = false;
      this.shopForm = {};
    },
    /*
     *  省市区下拉数据
     */
    getProvinceList() {
      const data = {
        pid: 1,
      };
      getRegionList(data).then((res) => {
        if (res.code === 1) {
          this.provinceList = res.data;
        }
      });
    },
    /*
     *   获取省id
     */
    handleProvie(id) {
      this.region_id = id;
      const data = {
        pid: id,
      };
      getRegionList(data).then((res) => {
        if (res.code === 1) {
          this.cityList = res.data;
        }
      });
    },
    /*
     *  获取城市id
     */
    handleCity(id) {
      this.region_id = id;
      const data = {
        pid: id,
      };
      getRegionList(data).then((res) => {
        if (res.code === 1) {
          this.areaList = res.data;
        }
      });
    },
    /*
     *  门店新增
     */
    handleAdd() {
      console.log("测试");
      this.visiable = true;
    },
    /*
     *   获取区id
     */
    handleArea(id) {
      this.region_id = id;
    },
    /*
     *   保存门店信息
     */
    storeSubmit(formName) {},
    /*
     *  门店Logo图片上传
     */
    handleStoreLogoUrlSuccess(res, file) {
      this.storeLogo_url = res.data.data;
      this.storeLogo_img = URL.createObjectURL(file.raw);
    },
    /*
     *  门店营业执照图片上传
     */
    handleStoreLicenseUrlSuccess(res, file) {
      this.storeLicense_url = res.data.data;
      this.storeLicense_img = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload() {},
  },
};
</script>

<style scoped lang="less">
/deep/ .title {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 16px;

  .span {
    margin: auto 10px auto 0;
    width: 4px;
    height: 16px;
    background: #5493f5;
    border-radius: 2px;
  }
}
/deep/ .line {
  display: inline-block;
  vertical-align: middle;
  margin: 0 30px;
  width: 1px;
  height: 40px;
  background: #e9e9e9;
}

/deep/ .avatar-uploader .el-upload {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
/deep/ .avatarOther {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
